import React from "react"
// import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
// import { Layout } from "../components/index"
import {
  RecruitLayout,
  RecruitLowerHeaderBg
} from '../components/Recruit/index'

import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { LowerHeader } from "../components/LowerPages/index"
import "../styles/LowerPage.scss";
import * as styles from "../styles/SuccessEntry.module.scss"

const SuccessEntry = () => {
  const breakpoints = useBreakpoint();

  const title1 = [
    { "id": 0, "text": "e" },
    { "id": 1, "text": "n" },
    { "id": 2, "text": "t" },
    { "id": 3, "text": "r" },
    { "id": 4, "text": "y" },
  ];
  return (
    <RecruitLayout className={`${styles.SuccessEntry}`} pageName={`entry`} title={`エントリー完了`}>
      <Seo
        title={`エントリー完了`}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/entry/entry_header-sp@2x.png"
              alt="entry_header"
              placeholder="none"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/entry/entry_header@2x.png"
              alt="entry_header"
              placeholder="none"
            />
        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`エントリー`}>
          <RecruitLowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div className={`LowerContent__inner`}>
            <div className={`LowerLead`}>
              <p>
                弊社への採用エントリーは下記のメールフォームをご利用ください。<br />
                申込者からいただいた個人情報は、慎重に取り扱い、<br />
                申込者の同意なしに第三者に提供または開示をすることはありません。<br />
                個人情報保護方針については<a href="/privacy/">こちら</a>をご覧ください。
              </p>
            </div>
            <div className={styles.SuccessEntry__result}>送信が完了いたしました。</div>
            <div className={`${styles.SuccessEntry__icon}`}>
              {
                breakpoints.sm ?
                  <StaticImage
                    layout="fullWidth"
                    src="../images/contact/success-sp.png"
                    alt="success"
                  />
                  :
                  <StaticImage
                    layout="fullWidth"
                    src="../images/contact/success.png"
                    alt="success"
                  />

              }
            </div>
            <div className={styles.SuccessEntry__endmsg}>
              <p>
                この度は、当組合にエントリーいただきありがとうございます。<br />
                取り急ぎ、お礼とエントリーの受付完了のご連絡をさせていただきます。<br />
                <br />
                今後のセミナーおよび選考会のスケジュールなどにつきましては、<br />
                詳細が決まり次第、担当者からご連絡いたします。もうしばらくお待ちください。<br />
                なお、当組合の最新情報は、<a href="/news/">News-お知らせ-</a>にも掲載予定ですので、ぜひご覧ください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </RecruitLayout>
  )
}
export default SuccessEntry